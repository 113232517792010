/**
 * Customize Bootstrap
 * These variables and definitions can be found in css/bootstrap/bootstrap/_variables.scss
 */
$blockquote-font-size:              19px;
$blockquote-border-color:           #ccc;
$brand-primary:                     #00bcff;
$component-active-bg:               transparent;
$component-active-color:            #44ace8;
$dropdown-bg:                       #000000;
$dropdown-link-color:               #ffffff;
$dropdown-link-hover-color:         #44ace8;
$dropdown-link-hover-bg:            #000000;
$font-family-sans-serif:            NotoSans, sans-serif !default;
$font-size-base:                    19px !default;
$font-size-h1:                      36px;
$font-size-h2:                      32px;
$font-size-h3:                      28px;
$font-size-h4:                      24px;
$font-size-h5:                      20px;
$font-size-h6:                      16px;
$line-height-base:                  1.4;
$navbar-inverse-bg:                 #000000;
$navbar-inverse-link-color:         #ffffff;
$navbar-inverse-link-hover-color:   #44ace8;
$navbar-inverse-link-active-bg:     transparent;
$navbar-inverse-link-active-color:  #44ace8;
$text-color:                        #000000;
$link-color:                        #f04848;
$link-hover-color:                  #000000;
$link-hover-decoration:             none;
