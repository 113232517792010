@charset "utf-8";

$navbar-height: 50px;
$logo-image:    'https://cdn.door43.org/assets/img/logo%402x.png';
$fa-font-path:  '/fonts/font-awesome';

// Import partials from `sass_dir` (defaults to `_sass`)
@import
  "custom_bootstrap",
  "fonts",
  "bootstrap/_bootstrap",
  "customize_theme",
  "font-awesome/font-awesome"
;
